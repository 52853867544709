/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import type {
  GetCompanyByWebsiteDto,
  GetQuestionTagsDto,
  GetSubProgramsDto,
  GetSubjectsDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  coursePrograms,
} from 'utilities';
import {
  useAuthStore,
} from './auth';
import {
  handleAxiosError,
} from '@/helpers';
import type {
  ChangeTypeOfKeys,
  IClientCompany,
  ICompanyInfo,
  ILoggedCompany,
  IQuestionTag,
  ISubProgram,
  ISubject,
} from '@/type';

export const useBackendStore = defineStore('backend', {
  state: () => ({
    subjectsForProgram: {
    } as Record<string, ISubject[]>,
    subProgramsForProgram: {
    } as Record<string, ISubProgram[]>,
    questionTags: [] as IQuestionTag[],

    loggedCompany: undefined as undefined | ILoggedCompany,
    clientCompanyInfo: {
    } as ICompanyInfo<IClientCompany>,
  }),
  actions: {
    async initOnAuthChange(): Promise<void> {
      const { user } = useAuthStore();

      if (!user || user.role === 'root') {
        this.subjectsForProgram = {
        };
        this.subProgramsForProgram = {
        };

        this.questionTags = [];
        this.loggedCompany = undefined;
        return;
      }

      this.fetchSubjectsForProgram();
      this.fetchQuestionTags();
      this.fetchSubProgramsForProgram();
      this.fetchLoggedCompany();
      this.fetchClientCompany(true);
    },
    async fetchSubjectsForProgram(programs = coursePrograms): Promise<void> {
      const subjectsForProgramPromise = async (program: string): Promise<void> => {
        try {
          const { company } = useAuthStore().loggedUser;

          const params: ChangeTypeOfKeys<GetSubjectsDto, 'filterBy' | 'fields', string | undefined> = {
            fields: '_id,title,program,chapters',
            search: program,
            filterBy: 'program',
            company,
          };

          const response = await axios.get<{
            docs: ISubject[]; totalData: number;
          }>('/subjects', {
            params,
          });

          this.subjectsForProgram[program] = response.data.docs;
        } catch (e: any) {
          const errMessage = `Error in getting subjects for program ${program}`;
          handleAxiosError(e, errMessage);
        }
      };

      const subjectPromises = programs.map((p) => subjectsForProgramPromise(p));
      await Promise.all(subjectPromises);
    },
    async fetchQuestionTags(): Promise<void> {
      try {
        const { company } = useAuthStore().loggedUser;

        const params: GetQuestionTagsDto = {
          company,
        };

        const response = await axios.get('/question-tags', {
          params,
        });

        this.questionTags = response.data.docs;
      } catch (e: any) {
        const errMessage = 'Error in getting question tags';
        handleAxiosError(e, errMessage);
      }
    },
    async fetchSubProgramsForProgram(programs = coursePrograms): Promise<void> {
      const subProgramsForProgramPromise = async (program: string): Promise<void> => {
        try {
          const { company } = useAuthStore().loggedUser;

          const params: ChangeTypeOfKeys<GetSubProgramsDto, 'filterBy' | 'fields', string | undefined> = {
            fields: ['_id', 'title'].join(','),
            filterBy: 'program',
            search: program,
            company,
          };

          const response = await axios.get('/sub-programs', {
            params,
          });

          this.subProgramsForProgram[program] = response.data.docs;
        } catch (e: any) {
          handleAxiosError(e, `Error in fetching subprograms for program ${program}`);
        }
      };

      const subProgramPromises = programs.map((p) => subProgramsForProgramPromise(p));
      await Promise.all(subProgramPromises);
    },
    async fetchClientCompany(hasUser: boolean = false): Promise<void> {
      try {
        const params: GetCompanyByWebsiteDto = {
          website: window.location.origin,
          type: 'client',
        };

        const url = hasUser ? '/companies/website' : '/public/companies/website';

        const response = await axios.get<ICompanyInfo<IClientCompany>>(url, {
          params,
        });

        this.clientCompanyInfo = response.data;
      } catch (e: any) {
        handleAxiosError(e, 'Error in fetching client company info');
      }
    },
    async fetchLoggedCompany(): Promise<void> {
      try {
        const params: GetCompanyByWebsiteDto = {
          website: window.location.origin,
          type: 'logged',
        };

        const response = await axios.get<{
          company: ILoggedCompany;
        }
        >('/companies/website', {
          params,
        });

        this.loggedCompany = response.data.company;
      } catch (e: any) {
        handleAxiosError(e, 'Error in fetching company info for logged user');
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
